<script setup lang="ts">
import Icon from './Icon.vue';

/**
 * Info Box that allows to display an informative message
 * @displayName InfoBox
 */
export type Variant = 'primary' | 'success' | 'error' | 'warning' | 'light' | 'transparent';

interface Props {
  /**
   * Box type
   * @default info
   * @values error, info, success, warning
   */
  variant?: Variant;
  /**
   * Specify if the infobox should be large
   */
  large?: boolean;
  /**
   * Title
   */
  title?: string;
  /**
   * Message
   */
  message?: string;
  /**
   * Take a 100% width
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Icon to display
   */
  icon?: string;
  /**
   * A button to close the infoBox
   * @default false
   */
  closeButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  fullWidth: false,
  title: undefined,
  message: undefined,
  large: false,
  icon: undefined,
  closeButton: false,
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const slots = useSlots();

const computedIcon = computed<string | undefined>(() => {
  if (props.icon) {
    return `-${[props.icon]}`;
  } else {
    switch (props.variant) {
      case 'primary':
        return 'help-circle';
      case 'error':
        return 'alert-circle';
      case 'success':
        return 'check';
      case 'warning':
        return 'alert-circle';
    }
  }
});

const hasIcon = computed(() => !!computedIcon.value || slots.icon);

const containerClasses = computed(() => {
  const { fullWidth, large } = props;

  return {
    '-large': large,
    '-full': fullWidth,
  };
});

const closeInfoBox = (): void => {
  emit('close');
};
</script>
<template>
  <div
    :class="['c-infobox', `-${variant}`, containerClasses]"
  >
    <span
      v-if="hasIcon"
      class="c-infobox__icon"
    >
      <Icon
        v-if="!!computedIcon"
        :name="computedIcon"
      />
      <slot
        v-else-if="slots.icon"
        name="icon"
      />
    </span>
    <div class="c-infobox__content | t-cms">
      <h6
        v-if="title"
      >
        {{ title }}
      </h6>
      <p
        v-if="message"
        v-html="message"
      />
      <slot />
    </div>
    <button
      v-if="closeButton || variant === 'error'"
      @click.prevent="closeInfoBox"
    >
      <Icon name="X" />
    </button>
  </div>
</template>
